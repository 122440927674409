<template>
	<S2SCard title="Addresses">
		<v-dialog v-model="mapDialog" scrollable fullscreen persistent max-width="500px" transition="dialog-transition">
			<v-card>
				<v-toolbar dark color="primary">
					<v-btn icon dark @click="mapDialog = false">
						<v-icon>close</v-icon>
					</v-btn>
					<v-toolbar-title>Address Location</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn dark text @click="viewType === 'create' ? onSave() : onUpdate()" cy-data="map-save">{{
							viewType === "create" ? "Save" : "Update"
						}}</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<leaflet-map v-if="mapDialog" v-bind:lat-lng.sync="latLng" :draggable="true" cy-data="leaflet-map"></leaflet-map>
			</v-card>
		</v-dialog>
		<v-layout row wrap>
			<v-flex md6 xs12>
				<v-autocomplete
					ref="search"
					v-model="selectedAddress"
					:filter="searchResultFilter"
					label="Search Address"
					:items="searchItems"
					return-object
					:search-input.sync="searchText"
					placeholder="eg: 15 Church street, Cape Town"
					cy-data="address-search"
				>
					<template slot="no-data">
						<v-list-item class="pl-2">
							No address found.
							<v-btn color="accent" @click="showDetails = true" :disabled="!enableManuelSearch">Enter Address Manually</v-btn>
						</v-list-item>
					</template>
				</v-autocomplete>
			</v-flex>
			<S2SFormGenerator v-if="showDetails" ref="form" :schema="addressSchema" :data="addressModel" :apiLookup="lookupsApi"></S2SFormGenerator>

			<v-flex xs12 class="text-sm-right" v-show="showDetails">
				<v-btn text :to="{ name: 'organisation-addresses' }" class="mr-2">Cancel</v-btn>
				<v-btn color="accent" @click="onNext()" cy-data="address-next">Next</v-btn>

				<v-flex v-if="viewType !== 'create'">
					<!-- <v-btn color="error" class="mt-10" @click="onDelete()" cy-data="address-delete">Delete</v-btn> -->

					<v-btn color="error" class="mt-10" @click.stop="deleteDialog = true">Delete</v-btn>

					<v-dialog v-model="deleteDialog" max-width="320">
						<v-card>
							<v-card-title class="headline">Are you sure you want to delete this address?</v-card-title>

							<v-card-text>
								<strong>This action cannot be undone.</strong>
							</v-card-text>

							<v-card-actions>
								<div class="flex-grow-1"></div>

								<v-btn color text @click="deleteDialog = false">Cancel</v-btn>

								<v-btn cy-data="address-delete" color="error" text @click="onDelete()">Delete</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-flex>
			</v-flex>
		</v-layout>
	</S2SCard>
</template>

<script>
import Vue from "vue";

import LeafletMap from "@/components/LeafletMap.vue";
import addressSchema from "../../static/config/forms/address.json";
import { LookupsApi } from "@/api/lookups-api";

export default Vue.extend({
	name: "Addresses",

	components: { LeafletMap },

	computed: {
		geoSearchAddresses: function() {
			return this.$store.state.organisations.addresses.geoSearchAddresses;
		},
		searchItems: function() {
			if (this.geoSearchAddresses.length) {
				const result = this.geoSearchAddresses.map(entry => {
					const suburb = entry.Location.Address.County;
					const city = entry.Location.Address.City;
					const streetName = entry.Location.Address.Street;
					const streetNumber = entry.Location.Address.HouseNumber;
					const province = entry.Location.Address.State;
					const postalCode = entry.Location.Address.PostalCode;
					const name = entry.Location.Address.Label;
					const latitude = entry.Location.DisplayPosition.Latitude;
					const longitude = entry.Location.DisplayPosition.Longitude;
					let description = entry.Location.Address.Label;
					description += suburb ? ", " + suburb : "";

					this.latLng = { lat: latitude, lng: longitude };

					return {
						text: description,
						value: { name, country: "South Africa", province, city, suburb, streetName, streetNumber, postalCode, latitude, longitude }
					};
				});

				return result;
			} else {
				return [];
			}
		},
		selectedAddressState: function() {
			return this.$store.state.organisations.addresses.selectedAddress;
		}
	},

	watch: {
		searchText: async function() {
			if (!this.searchText) return;
			try {
				await this.$store.dispatch("organisations/addresses/searchAddress", this.searchText);
				this.enableManuelSearch = true;
			} catch (error) {
				this.enableManuelSearch = true;
			}
		},
		selectedAddress: function(val) {
			if (val) this.showDetails = true;
			this.addressModel = { ...this.addressModel, ...val.value };
		},
		selectedAddressState: {
			deep: true,
			immediate: true,
			handler(val) {
				if (!val) return;
				this.showDetails = true;
				this.addressModel = val;
				this.latLng = { lat: val.latitude, lng: val.longitude };
			}
		},
		"$route.params.addressId": {
			deep: true,
			immediate: true,
			handler(val) {
				if (val) this.viewType = "edit";
				else this.$store.dispatch("organisations/addresses/clearSelectedAddress");

				if (!this.selectedAddressState && val) this.$store.dispatch("organisations/addresses/fetchAddress", +val);
			}
		}
	},

	data: function() {
		return {
			searchText: null,
			selectedAddress: null,
			addressSchema: addressSchema,
			addressModel: {},
			showDetails: false,
			mapDialog: false,
			deleteDialog: false,
			enableManuelSearch: false,
			latLng: {},
			lookupsApi: LookupsApi.fetchLookups,
			viewType: "create"
		};
	},

	methods: {
		async onSave() {
			const form = this.$refs["form"];
			const valid = await form.validate();
			if (!valid) return;
			form.model.latitude = this.latLng.lat;
			form.model.longitude = this.latLng.lng;
			await this.$store.dispatch("organisations/addresses/createAddress", form.model);
			this.$router.push({ name: "organisation-addresses" });
		},
		async onUpdate() {
			const form = this.$refs["form"];
			const valid = await form.validate();
			if (!valid) return;
			form.model.latitude = this.latLng.lat;
			form.model.longitude = this.latLng.lng;
			await this.$store.dispatch("organisations/addresses/updateAddress", form.model);
			this.$router.push({ name: "organisation-addresses" });
		},
		async onNext() {
			const form = this.$refs["form"];
			const valid = await form.validate();
			if (valid) this.mapDialog = true;
		},
		async onDelete() {
			this.$store.dispatch("organisations/addresses/deleteAddress", this.selectedAddressState);
			// go back to previous route
			this.$router.go(-1);
		},
		searchResultFilter() {
			return true;
		}
	}
});
</script>

<style>
.flex.full-label {
	flex-basis: 100%;
	max-width: 100%;
}
</style>
